import { useContext, useEffect, useState } from 'react';
import LoggedOutLayout from '../components/LoggedOutLayout';
import MfaValidate from '../components/Mfa/MfaValidate';
import MfaMethodSelect from '../components/Mfa/MfaMethodSelect';
import { userLogin } from '../lib/api';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import type { LocationDescriptor } from 'history';
import { RedirectLocationState } from '../routes/ProtectedRoute';
import LoginForm from '../components/LoginForm';
import { MfaMethod, MfaMethodTypes } from 'types';

function Login() {
   const configuredMfaMethods: MfaMethodTypes[] = ['email']; //['email', 'TOTP', 'SMS'];

   const { user, handleUserLogin } = useContext(UserContext);

   const history = useHistory();
   const location = useLocation<RedirectLocationState>();

   const urlParams = new URLSearchParams(location.search);
   const displayEmail = urlParams.get('u') || '';

   const [availableMethods, setAvailableMethods] = useState<MfaMethod[]>();
   const [selectedMethodType, setSelectedMethodType] =
      useState<MfaMethodTypes>('email');
   const [selectedMethod, setSelectedMethod] = useState<MfaMethod>();

   const [formValues, setFormValues] = useState({
      password: '',
      email: displayEmail,
   });
   const [error, setError] = useState('');

   const [showLoginForm, setShowLoginForm] = useState(true);

   const [showMfaValidate, setShowMfaValidate] = useState(false);
   const [showMfaMethodSelect, setShowMfaMethodSelect] = useState(false);
   const [isMfaSetup, setIsMfaSetup] = useState(false);

   const [setupOptional, setSetupOptional] = useState(false);

   // === setting this to true until we go live ====
   const [doRedirect, setDoRedirect] = useState(true);

   const redirect: LocationDescriptor = location.state?.from || '/';

   useEffect(() => {
      if (user && doRedirect) {
         history.replace(redirect);
      }
   }, [user, doRedirect]);

   useEffect(() => {
      if (availableMethods) {
         if (availableMethods.length === 1) {
            setSelectedMethod(availableMethods[0]);
            setSelectedMethodType(availableMethods[0].methodType);
            setShowMfaValidate(true);
         } else {
            setShowMfaMethodSelect(true);
         }
      }
   }, [availableMethods]);

   const setActiveAvailableMethods = (availableMethods?: MfaMethod[]) => {
      const activeMethods = availableMethods?.filter(method => method.active);
      if (!activeMethods) {
         throw new Error('No methods configured');
      }
      setAvailableMethods(activeMethods);
   };

   const handleLoginFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
         ...formValues,
         [e.target.name]: e.target.value,
      });
   };

   const handleLoginFormSubmit = async (
      e: React.FormEvent<HTMLFormElement>,
   ) => {
      e.preventDefault();
      try {
         const res = await userLogin(formValues);
         setShowLoginForm(false);

         if (res.type === 'mfaAuth') {
            setError('');
            if (res.allowMethodSetup) {
               setIsMfaSetup(true);
               configuredMfaMethods.length > 1
                  ? setShowMfaMethodSelect(true)
                  : setShowMfaValidate(true);
            } else {
               setActiveAvailableMethods(res.methods);
            }
         } else {
            setIsMfaSetup(true);
            setSetupOptional(true);
            setShowMfaMethodSelect(true);
            handleUserLogin();
         }
      } catch (err) {
         setError(
            err.message ??
               `The email and password you entered did not match our records.
         Please double-check and try again.`,
         );
      }
   };

   return (
      <LoggedOutLayout>
         {/* <!-- Centered White Card Container -- Content Starts Here--> */}

         {showLoginForm ? (
            <LoginForm
               handleSubmit={handleLoginFormSubmit}
               formValues={formValues}
               handleChange={handleLoginFormChange}
               error={error}
               setError={setError}
               redirect={redirect}
            />
         ) : null}

         {showMfaMethodSelect ? (
            <MfaMethodSelect
               setup={isMfaSetup}
               setupOptional={setupOptional}
               selectableMethods={availableMethods}
               configuredMethods={configuredMfaMethods}
               error={error}
               setError={setError}
               setDoRedirect={setDoRedirect}
            />
         ) : null}

         {showMfaValidate ? (
            <MfaValidate
               methodType={selectedMethodType}
               selectedMethodInfo={selectedMethod}
               error={error}
               setError={setError}
               handleUserLogin={handleUserLogin}
            />
         ) : null}

         {/* <!-- Centered White Card Container -- Content Ends Here--> */}
      </LoggedOutLayout>
   );
}

export default Login;
