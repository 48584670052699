import { MfaMethod, MfaMethodTypes } from 'types';
import MfaMethodItem from './MethodItem';
import { Dispatch, SetStateAction } from 'react';

interface Props {
   setup: boolean;
   setupOptional?: boolean;
   selectableMethods?: MfaMethod[];
   configuredMethods?: MfaMethodTypes[];
   error: string;
   setError: Dispatch<SetStateAction<string>>;
   setDoRedirect: Dispatch<SetStateAction<boolean>>;
}

export default function mfaMethodSelect({
   setup,
   setupOptional,
   selectableMethods,
   configuredMethods,
   error,
   setError,
   setDoRedirect: doRedirect,
}: Props) {
   return (
      <>
         <h2 className="mb-6 text-left text-3xl font-extrabold text-gray-900">
            Multi-factor authentication
         </h2>
         {error ? (
            <div className="bg-red-200 p-4 rounded-md text-left my-2">
               {error}
            </div>
         ) : null}
         <div className="p-4 bg-gray-100 rounded-md text-left my-6">
            {!setup ? (
               <p>
                  To keep your account secure, please select a method to verify
                  your identity:
               </p>
            ) : null}
            {setup && !setupOptional ? (
               <>
                  <p>
                     To keep your account secure, we now require an additional
                     form of authentication when you login.
                  </p>
                  <p>
                     Please select a method and we will set it up for you. You
                     can have more than one method.
                  </p>
               </>
            ) : null}
            {setup && setupOptional ? (
               <>
                  <p>
                     To keep your account secure, we recommend adding an
                     additional form of authentication to your login.
                  </p>
                  <p>
                     If you would like to set this up now, please select the
                     method you wish to use. You can have more than one method.
                  </p>
               </>
            ) : null}
         </div>
         <form className="space-y-6" action="#" method="POST">
            <fieldset>
               <ul className="relative bg-white rounded-md -space-y-px">
                  {selectableMethods
                     ? selectableMethods?.map((item, index) => (
                          <MfaMethodItem
                             key={index}
                             methodType={item.methodType}
                             selectedMethodInfo={item}
                          />
                       ))
                     : configuredMethods?.map((item, index) => (
                          <MfaMethodItem key={index} methodType={item} />
                       ))}
               </ul>
            </fieldset>
            <div>
               <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
               >
                  Submit
               </button>
            </div>
            {setupOptional && (
               <div className="p-4 rounded-md text-center my-2">
                  <button
                     type="button"
                     className="link my-4 block text-sm text-blue-400"
                     onClick={() => doRedirect(true)}
                  >
                     Skip for now
                  </button>
                  <div className="text-gray-400 text-xs">
                     Note that at some point in the future multi-factor
                     authentication will be required
                  </div>
               </div>
            )}
         </form>
      </>
   );
}
