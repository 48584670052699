import { MfaMethod, MfaMethodTypes } from 'types';

interface Props {
   selectedMethodInfo?: MfaMethod;
   methodType: MfaMethodTypes;
}

export default function MethodItem({ methodType, selectedMethodInfo }: Props) {
   const displayName: Record<MfaMethodTypes, string> = {
      email: 'Email',
      TOTP: 'App Push',
      SMS: 'SMS',
      passkey: '',
   };

   return (
      <li>
         {/* On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" */}
         <div className="relative border rounded-tl-md rounded-tr-md p-4 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-3">
            <label className="flex items-center text-sm cursor-pointer">
               <input
                  name="pricing_plan"
                  type="radio"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300"
               />
               <span className="ml-3 font-medium text-gray-900">
                  {displayName[methodType]}
               </span>
            </label>
         </div>
      </li>
   );
}
